import React from 'react'
import Layout from '../components/layout'
import { Link } from 'gatsby'
import resumePDF from '../files/Collin Waid Resume.pdf'

import { InlineIcon } from '@iconify/react'
import downloadOutlined from '@iconify/icons-ant-design/download-outlined'

const ResumePage = () => (
  <Layout>
    <div className='PageHeader'>
      <h1>Resume</h1>
    </div>
    <div className='ResumeContent constrict-content'>
      <p className='DownloadResume'>
        <a className='DownloadResume__link' href={resumePDF} rel='nofollow' download='Collin Waid Resume'>
          <InlineIcon icon={downloadOutlined} /> Download Resume (PDF)
        </a>
      </p>
      <p>
        or
        <br/><br/>
        view the <Link to='/portfolio/'>portfolio</Link> section.
      </p>
    </div>
  </Layout>
)

export default ResumePage
